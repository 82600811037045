@import '~bootstrap/scss/mixins/breakpoints';
@import "common";

@media (min-width:501px)
{
.navbar-main {
  background-color: transparentize(#36668E, 0.05);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
}
/* .navbar-nav .nav-link {
  text-transform: uppercase;
} */

@include media-breakpoint-up(xl) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ul.navbar-nav {
    li {
      position: relative;
    }
    li.nav-item-pipe:after {
      top: 7px;
      right: 0;
      position: absolute;
      content: "|";
      opacity: 0.2;
    }

    li.nav-item-language-selector .nav-link {
      text-transform: none;
      border: 1px solid $gray-200;
      padding: 0.25rem 0.5rem;
      margin-top: 0.25rem;
      margin-left: 1rem;
    }

    li.nav-item-social .nav-link {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  ul.navbar-nav {
    li.nav-item-language-selector .nav-link {
      text-transform: none;
      border-top: 1px solid $gray-200;
    }
  }
}

.navbar-brand {
  @include media-breakpoint-up(xl) {
    margin-left: 7rem;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
  img {
    max-width: 200px;
    @include media-breakpoint-up(sm) {
      max-width: 300px;
    }

  }
}



@media (min-width:501px)
{
.navbar-nav .nav-link {
  color:#fff;
}

.navbar-nav .show > .nav-link, .navbar-nav .active > .nav-link, .navbar-nav .nav-link.show, .navbar-nav .nav-link.active {
  color:#fff;
}

.nav-principal {
  color:#fff;
}
.shrink .nav-principal{
  color:#36668E;
}

.navbar-light .nav-principal {
  color:#36668E;
}


ul.navbar-nav li.nav-item-pipe:after {
  opacity: 1;
  color:#fff;
}

.navbar-nav .nav-item .nav-link:hover{color:#009FDF;}
.navbar-expand-md .navbar-nav .dropdown-menu{
	border-top:3px solid #fff;
}
.dropdown-item:hover{background-color:#009FDF;color:#fff;}

}


.shrink .navbar-nav .nav-link {
  color:#36668E;
}

.shrink .navbar-nav .show > .nav-link, .shrink .navbar-nav .active > .nav-link, .shrink .navbar-nav .nav-link.show, .shrink .navbar-nav .nav-link.active {
  color: #36668E;
  font-weight: bold;
}


.shrink ul.navbar-nav li.nav-item-pipe:after {
  opacity: 1;
  color: #009FDF;
}

.shrink .navbar-nav .nav-item .nav-link:hover, .nav-link .fa:hover, .shrink .nav-link .fa:hover{color:#009FDF;}
.shrink .navbar-expand-md .navbar-nav .dropdown-menu{
	border-top:3px solid #009FDF;
}
.shrink .dropdown-item:hover{background-color:#009FDF;color:#fff;}


.nav-link .fa, .list-inline-item .fa{
  color:  white;
}

.shrink .nav-link .fa, footer .nav-link .fa:hover, .list-inline-item .fa:hover {
  color:  #36668E;
}


nav{
    -webkit-transition: padding-top .3s,padding-bottom .3s;
    -moz-transition: padding-top .3s,padding-bottom .3s;
    transition: padding-top .3s,padding-bottom .3s;
    border: none;
	}
	
 .shrink {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #ffffff;
}

/* mobile view */
@media (max-width:500px)
{
  .navbar-main {
    background-color: transparentize(#36668E, 0.05);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
	.navbar-nav{
		background-color:#ffffff50;
		border-top:3px solid #009FDF;
		z-index:1;
		margin-top:5px;
    }
  .navbar-toggler{
    color: #009FDF;
  }
	.navbar-nav .nav-item .nav-link{
	padding: 0.7em 1em!important;
	font-size: 100%;
    font-weight: 500;
    
    }


}


.mt-6 {
  margin-top: ($spacer * 7) !important;
}
.pt-6 {
  padding-top: ($spacer * 10) !important;
}


