@import "variables_custom";

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.background-cover-image {
  background-position: center center;
  background-size: cover;
  color: white;
  position: relative;

  > .container {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 49, 99, 0.5);
    /* background: transparentize(black, 0.5); */
    z-index: 1;
  }
}



.retos {
  background-image: url("/images/public/home/retos.jpg");
}
.aerodromo {
  background-image: url("/images/public/home/aerodromo.jpg");
}

.ala {
  background-image: url("/images/public/home/CIVIL UAVS INICIATIVE/fondo.jpg");
}

.cielo {
  background-image: url("/images/public/que es/que es2.png");
}

.incubacion {
  background-image: url("/images/public/que es/incubacion.jpg");
}
.aceleracion {
  background-image: url("/images/public/que es/aceleracion.jpg");
}

.quienes-somos {
  background-image: url("/images/public/quienes somos/quienes somos.jpg");
}

.instalaciones-01 {
  background-image: url("/images/public/que es/instalaciones-01.jpg");
}
.instalaciones-02 {
  background-image: url("/images/public/que es/instalaciones-02.jpg");
}




.bg-gray {
  background-color: $gray-200;
}

.header {
  min-height: 15rem;
  background-size: cover;
  background-position: center center;
  position: relative;

  > .container {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(black, 0.9);
    z-index: 1;
  }
}


.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

@media (min-width:768px)
{
 .frontside .card,
.backside .card {
    min-height: 300px;
} 
}

@media (max-width:767px)
{
 .frontside .card,
.backside .card {
    min-height: 197px;
} 
}
@media (min-width:768px)
{
  .centrar-xs {
    text-align: left !important;
} 
}

@media (max-width:767px)
{
  .centrar-xs {
    text-align: center !important;
} 
}

.tematicas .card {
  /* height:18em; */
  border:0;

}


//Project cards
.project-img-card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.project-img-card-block {
  font-size: 1em;
  position: relative;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, .1);
  box-shadow: none;
  background: white;
}

.project-img-card-img-top {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}

.project-img-card-title {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
  color: #009FDF;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 1em;
}

.project-img-card-text {
  clear: both;
  margin-top: .5em;
  color: rgba(7, 9, 5, .68);
}

.project-img-card-footer {
  font-size: 1em;
  position: static;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: 0.6em 1em 1em 1em;
  color: rgba(0, 0, 0, .4);
  border-top: 1px solid rgba(0, 0, 0, .05) !important;
  background: #fbfbfd;
  border-radius: 0px 0px 5px 5px;
}

.project-img-card-inverse .btn {
  border: 1px solid rgba(0, 0, 0, .05);
}

.project-img-card-project {
  position: absolute;
  top: -0.5em;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: 4em;
  height: 4em;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 50%;
}

.project-img-card-project-avatar {
  display: block;
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.project-img-card-project-inline {
  position: relative;
  top: 0;
  display: inline-block;
}

.project-img-card-project-inline ~ .project-img-card-title {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
}

.project-img-card-text-bold {
  font-weight: 700;
}


//Fade cards
.link-external {
  position: relative;
  display: inline-block;
  background-color: white;
  overflow: hidden;
  margin:0px;
  z-index: 2;


  .link-external-ovelay {
    opacity: 0;
    transition: opacity 300ms;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize(white, 0.30);
    color: white;
    .fa-external-link {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .front {
    z-index: 1;
    position: relative;
    transform: scale(0.8);
    transition: transform 300ms;
  }
  &:hover {
    .front {
      transform: scale(1.1);
    }
    .link-external-ovelay {
      opacity: 1;
    }
  }

  .btn-like {
    cursor: pointer;
  }
}