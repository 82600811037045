@import "variables_custom";
@import '~bootstrap/scss/mixins/breakpoints';

.cookies-alert {
  position: fixed;
  bottom: 1rem;
  left: 0rem;
  width: 100%;
  padding: 30px 4rem;
  background: transparentize(black,0.2);
  text-align: left;
  z-index: 100000;
  font-weight: 100;
  color: darken(white, 10%);
  font-size: 12px;

  a {
    color: darken(white, 10%);;
    text-decoration: underline;
  }

}
.cookies-alert-close {
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background: none;
  opacity: 0.40;
  color: #ffffff;
}

@include media-breakpoint-up(sm) {
  .cookies-alert {
    right: 0px;
    width: 100%;
  }
}