@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
.text-blue {
  color: #0981AC;
}

.bg-blue {
  background-color: #0981AC;
}

.border-blue {
  border-color: #0981AC !important;
}

.text-indigo {
  color: #6610f2;
}

.bg-indigo {
  background-color: #6610f2;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.text-purple {
  color: #6f42c1;
}

.bg-purple {
  background-color: #6f42c1;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.text-pink {
  color: #e83e8c;
}

.bg-pink {
  background-color: #e83e8c;
}

.border-pink {
  border-color: #e83e8c !important;
}

.text-red {
  color: #CF4444;
}

.bg-red {
  background-color: #CF4444;
}

.border-red {
  border-color: #CF4444 !important;
}

.text-orange {
  color: #fd7e14;
}

.bg-orange {
  background-color: #fd7e14;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.text-yellow {
  color: #EBBB54;
}

.bg-yellow {
  background-color: #EBBB54;
}

.border-yellow {
  border-color: #EBBB54 !important;
}

.text-green {
  color: #94BA65;
}

.bg-green {
  background-color: #94BA65;
}

.border-green {
  border-color: #94BA65 !important;
}

.text-teal {
  color: #20c997;
}

.bg-teal {
  background-color: #20c997;
}

.border-teal {
  border-color: #20c997 !important;
}

.text-cyan {
  color: #0981AC;
}

.bg-cyan {
  background-color: #0981AC;
}

.border-cyan {
  border-color: #0981AC !important;
}

.text-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.border-white {
  border-color: #FFFFFF !important;
}

.text-morado {
  color: #6662AC;
}

.bg-morado {
  background-color: #6662AC;
}

.border-morado {
  border-color: #6662AC !important;
}

.text-gray {
  color: #e9ecef;
}

.bg-gray {
  background-color: #e9ecef;
}

.border-gray {
  border-color: #e9ecef !important;
}

.text-darkgray {
  color: #4D4D4D;
}

.bg-darkgray {
  background-color: #4D4D4D;
}

.border-darkgray {
  border-color: #4D4D4D !important;
}

.text-bluebf {
  color: #009fdf;
}

.bg-bluebf {
  background-color: #009fdf;
}

.border-bluebf {
  border-color: #009fdf !important;
}

.text-bluebf-light {
  color: #dbf1f9;
}

.bg-bluebf-light {
  background-color: #dbf1f9;
}

.border-bluebf-light {
  border-color: #dbf1f9 !important;
}

.text-bluebf-mid {
  color: #39b2dd;
}

.bg-bluebf-mid {
  background-color: #39b2dd;
}

.border-bluebf-mid {
  border-color: #39b2dd !important;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.background-cover-image {
  background-position: center center;
  background-size: cover;
  color: white;
  position: relative;
}

.background-cover-image > .container {
  position: relative;
  z-index: 2;
}

.background-cover-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 49, 99, 0.5);
  /* background: transparentize(black, 0.5); */
  z-index: 1;
}

.retos {
  background-image: url("/images/public/home/retos.jpg");
}

.aerodromo {
  background-image: url("/images/public/home/aerodromo.jpg");
}

.ala {
  background-image: url("/images/public/home/CIVIL UAVS INICIATIVE/fondo.jpg");
}

.cielo {
  background-image: url("/images/public/que es/que es2.png");
}

.incubacion {
  background-image: url("/images/public/que es/incubacion.jpg");
}

.aceleracion {
  background-image: url("/images/public/que es/aceleracion.jpg");
}

.quienes-somos {
  background-image: url("/images/public/quienes somos/quienes somos.jpg");
}

.instalaciones-01 {
  background-image: url("/images/public/que es/instalaciones-01.jpg");
}

.instalaciones-02 {
  background-image: url("/images/public/que es/instalaciones-02.jpg");
}

.bg-gray {
  background-color: #e9ecef;
}

.header {
  min-height: 15rem;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.header > .container {
  position: relative;
  z-index: 2;
}

.header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px #9e9e9e;
  -moz-box-shadow: 5px 7px 9px -4px #9e9e9e;
  box-shadow: 5px 7px 9px -4px #9e9e9e;
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

@media (min-width: 768px) {
  .frontside .card,
  .backside .card {
    min-height: 300px;
  }
}

@media (max-width: 767px) {
  .frontside .card,
  .backside .card {
    min-height: 197px;
  }
}

@media (min-width: 768px) {
  .centrar-xs {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .centrar-xs {
    text-align: center !important;
  }
}

.tematicas .card {
  /* height:18em; */
  border: 0;
}

.project-img-card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.project-img-card-block {
  font-size: 1em;
  position: relative;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  box-shadow: none;
  background: white;
}

.project-img-card-img-top {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}

.project-img-card-title {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
  color: #009FDF;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 1em;
}

.project-img-card-text {
  clear: both;
  margin-top: .5em;
  color: rgba(7, 9, 5, 0.68);
}

.project-img-card-footer {
  font-size: 1em;
  position: static;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: 0.6em 1em 1em 1em;
  color: rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  background: #fbfbfd;
  border-radius: 0px 0px 5px 5px;
}

.project-img-card-inverse .btn {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.project-img-card-project {
  position: absolute;
  top: -0.5em;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: 4em;
  height: 4em;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 50%;
}

.project-img-card-project-avatar {
  display: block;
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.project-img-card-project-inline {
  position: relative;
  top: 0;
  display: inline-block;
}

.project-img-card-project-inline ~ .project-img-card-title {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
}

.project-img-card-text-bold {
  font-weight: 700;
}

.link-external {
  position: relative;
  display: inline-block;
  background-color: white;
  overflow: hidden;
  margin: 0px;
  z-index: 2;
}

.link-external .link-external-ovelay {
  opacity: 0;
  transition: opacity 300ms;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}

.link-external .link-external-ovelay .fa-external-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-external .front {
  z-index: 1;
  position: relative;
  transform: scale(0.8);
  transition: transform 300ms;
}

.link-external:hover .front {
  transform: scale(1.1);
}

.link-external:hover .link-external-ovelay {
  opacity: 1;
}

.link-external .btn-like {
  cursor: pointer;
}

.text-blue {
  color: #0981AC;
}

.bg-blue {
  background-color: #0981AC;
}

.border-blue {
  border-color: #0981AC !important;
}

.text-indigo {
  color: #6610f2;
}

.bg-indigo {
  background-color: #6610f2;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.text-purple {
  color: #6f42c1;
}

.bg-purple {
  background-color: #6f42c1;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.text-pink {
  color: #e83e8c;
}

.bg-pink {
  background-color: #e83e8c;
}

.border-pink {
  border-color: #e83e8c !important;
}

.text-red {
  color: #CF4444;
}

.bg-red {
  background-color: #CF4444;
}

.border-red {
  border-color: #CF4444 !important;
}

.text-orange {
  color: #fd7e14;
}

.bg-orange {
  background-color: #fd7e14;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.text-yellow {
  color: #EBBB54;
}

.bg-yellow {
  background-color: #EBBB54;
}

.border-yellow {
  border-color: #EBBB54 !important;
}

.text-green {
  color: #94BA65;
}

.bg-green {
  background-color: #94BA65;
}

.border-green {
  border-color: #94BA65 !important;
}

.text-teal {
  color: #20c997;
}

.bg-teal {
  background-color: #20c997;
}

.border-teal {
  border-color: #20c997 !important;
}

.text-cyan {
  color: #0981AC;
}

.bg-cyan {
  background-color: #0981AC;
}

.border-cyan {
  border-color: #0981AC !important;
}

.text-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.border-white {
  border-color: #FFFFFF !important;
}

.text-morado {
  color: #6662AC;
}

.bg-morado {
  background-color: #6662AC;
}

.border-morado {
  border-color: #6662AC !important;
}

.text-gray {
  color: #e9ecef;
}

.bg-gray {
  background-color: #e9ecef;
}

.border-gray {
  border-color: #e9ecef !important;
}

.text-darkgray {
  color: #4D4D4D;
}

.bg-darkgray {
  background-color: #4D4D4D;
}

.border-darkgray {
  border-color: #4D4D4D !important;
}

.text-bluebf {
  color: #009fdf;
}

.bg-bluebf {
  background-color: #009fdf;
}

.border-bluebf {
  border-color: #009fdf !important;
}

.text-bluebf-light {
  color: #dbf1f9;
}

.bg-bluebf-light {
  background-color: #dbf1f9;
}

.border-bluebf-light {
  border-color: #dbf1f9 !important;
}

.text-bluebf-mid {
  color: #39b2dd;
}

.bg-bluebf-mid {
  background-color: #39b2dd;
}

.border-bluebf-mid {
  border-color: #39b2dd !important;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.background-cover-image {
  background-position: center center;
  background-size: cover;
  color: white;
  position: relative;
}

.background-cover-image > .container {
  position: relative;
  z-index: 2;
}

.background-cover-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 49, 99, 0.5);
  /* background: transparentize(black, 0.5); */
  z-index: 1;
}

.retos {
  background-image: url("/images/public/home/retos.jpg");
}

.aerodromo {
  background-image: url("/images/public/home/aerodromo.jpg");
}

.ala {
  background-image: url("/images/public/home/CIVIL UAVS INICIATIVE/fondo.jpg");
}

.cielo {
  background-image: url("/images/public/que es/que es2.png");
}

.incubacion {
  background-image: url("/images/public/que es/incubacion.jpg");
}

.aceleracion {
  background-image: url("/images/public/que es/aceleracion.jpg");
}

.quienes-somos {
  background-image: url("/images/public/quienes somos/quienes somos.jpg");
}

.instalaciones-01 {
  background-image: url("/images/public/que es/instalaciones-01.jpg");
}

.instalaciones-02 {
  background-image: url("/images/public/que es/instalaciones-02.jpg");
}

.bg-gray {
  background-color: #e9ecef;
}

.header {
  min-height: 15rem;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.header > .container {
  position: relative;
  z-index: 2;
}

.header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px #9e9e9e;
  -moz-box-shadow: 5px 7px 9px -4px #9e9e9e;
  box-shadow: 5px 7px 9px -4px #9e9e9e;
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

@media (min-width: 768px) {
  .frontside .card,
  .backside .card {
    min-height: 300px;
  }
}

@media (max-width: 767px) {
  .frontside .card,
  .backside .card {
    min-height: 197px;
  }
}

@media (min-width: 768px) {
  .centrar-xs {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .centrar-xs {
    text-align: center !important;
  }
}

.tematicas .card {
  /* height:18em; */
  border: 0;
}

.project-img-card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.project-img-card-block {
  font-size: 1em;
  position: relative;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  box-shadow: none;
  background: white;
}

.project-img-card-img-top {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}

.project-img-card-title {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
  color: #009FDF;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 1em;
}

.project-img-card-text {
  clear: both;
  margin-top: .5em;
  color: rgba(7, 9, 5, 0.68);
}

.project-img-card-footer {
  font-size: 1em;
  position: static;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: 0.6em 1em 1em 1em;
  color: rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  background: #fbfbfd;
  border-radius: 0px 0px 5px 5px;
}

.project-img-card-inverse .btn {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.project-img-card-project {
  position: absolute;
  top: -0.5em;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: 4em;
  height: 4em;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 50%;
}

.project-img-card-project-avatar {
  display: block;
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.project-img-card-project-inline {
  position: relative;
  top: 0;
  display: inline-block;
}

.project-img-card-project-inline ~ .project-img-card-title {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
}

.project-img-card-text-bold {
  font-weight: 700;
}

.link-external {
  position: relative;
  display: inline-block;
  background-color: white;
  overflow: hidden;
  margin: 0px;
  z-index: 2;
}

.link-external .link-external-ovelay {
  opacity: 0;
  transition: opacity 300ms;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}

.link-external .link-external-ovelay .fa-external-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-external .front {
  z-index: 1;
  position: relative;
  transform: scale(0.8);
  transition: transform 300ms;
}

.link-external:hover .front {
  transform: scale(1.1);
}

.link-external:hover .link-external-ovelay {
  opacity: 1;
}

.link-external .btn-like {
  cursor: pointer;
}

@media (min-width: 501px) {
  .navbar-main {
    background-color: rgba(54, 102, 142, 0.95);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
}

/* .navbar-nav .nav-link {
  text-transform: uppercase;
} */
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  ul.navbar-nav li {
    position: relative;
  }
  ul.navbar-nav li.nav-item-pipe:after {
    top: 7px;
    right: 0;
    position: absolute;
    content: "|";
    opacity: 0.2;
  }
  ul.navbar-nav li.nav-item-language-selector .nav-link {
    text-transform: none;
    border: 1px solid #e9ecef;
    padding: 0.25rem 0.5rem;
    margin-top: 0.25rem;
    margin-left: 1rem;
  }
  ul.navbar-nav li.nav-item-social .nav-link {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  ul.navbar-nav li.nav-item-language-selector .nav-link {
    text-transform: none;
    border-top: 1px solid #e9ecef;
  }
}

@media (min-width: 1200px) {
  .navbar-brand {
    margin-left: 7rem;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
}

.navbar-brand img {
  max-width: 200px;
}

@media (min-width: 576px) {
  .navbar-brand img {
    max-width: 300px;
  }
}

@media (min-width: 501px) {
  .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-nav .show > .nav-link, .navbar-nav .active > .nav-link, .navbar-nav .nav-link.show, .navbar-nav .nav-link.active {
    color: #fff;
  }
  .nav-principal {
    color: #fff;
  }
  .shrink .nav-principal {
    color: #36668E;
  }
  .navbar-light .nav-principal {
    color: #36668E;
  }
  ul.navbar-nav li.nav-item-pipe:after {
    opacity: 1;
    color: #fff;
  }
  .navbar-nav .nav-item .nav-link:hover {
    color: #009FDF;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border-top: 3px solid #fff;
  }
  .dropdown-item:hover {
    background-color: #009FDF;
    color: #fff;
  }
}

.shrink .navbar-nav .nav-link {
  color: #36668E;
}

.shrink .navbar-nav .show > .nav-link, .shrink .navbar-nav .active > .nav-link, .shrink .navbar-nav .nav-link.show, .shrink .navbar-nav .nav-link.active {
  color: #36668E;
  font-weight: bold;
}

.shrink ul.navbar-nav li.nav-item-pipe:after {
  opacity: 1;
  color: #009FDF;
}

.shrink .navbar-nav .nav-item .nav-link:hover, .nav-link .fa:hover, .shrink .nav-link .fa:hover {
  color: #009FDF;
}

.shrink .navbar-expand-md .navbar-nav .dropdown-menu {
  border-top: 3px solid #009FDF;
}

.shrink .dropdown-item:hover {
  background-color: #009FDF;
  color: #fff;
}

.nav-link .fa, .list-inline-item .fa {
  color: white;
}

.shrink .nav-link .fa, footer .nav-link .fa:hover, .list-inline-item .fa:hover {
  color: #36668E;
}

nav {
  -webkit-transition: padding-top .3s,padding-bottom .3s;
  -moz-transition: padding-top .3s,padding-bottom .3s;
  transition: padding-top .3s,padding-bottom .3s;
  border: none;
}

.shrink {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffffff;
}

/* mobile view */
@media (max-width: 500px) {
  .navbar-main {
    background-color: rgba(54, 102, 142, 0.95);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-nav {
    background-color: #ffffff50;
    border-top: 3px solid #009FDF;
    z-index: 1;
    margin-top: 5px;
  }
  .navbar-toggler {
    color: #009FDF;
  }
  .navbar-nav .nav-item .nav-link {
    padding: 0.7em 1em !important;
    font-size: 100%;
    font-weight: 500;
  }
}

.mt-6 {
  margin-top: 7rem !important;
}

.pt-6 {
  padding-top: 10rem !important;
}

.text-blue {
  color: #0981AC;
}

.bg-blue {
  background-color: #0981AC;
}

.border-blue {
  border-color: #0981AC !important;
}

.text-indigo {
  color: #6610f2;
}

.bg-indigo {
  background-color: #6610f2;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.text-purple {
  color: #6f42c1;
}

.bg-purple {
  background-color: #6f42c1;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.text-pink {
  color: #e83e8c;
}

.bg-pink {
  background-color: #e83e8c;
}

.border-pink {
  border-color: #e83e8c !important;
}

.text-red {
  color: #CF4444;
}

.bg-red {
  background-color: #CF4444;
}

.border-red {
  border-color: #CF4444 !important;
}

.text-orange {
  color: #fd7e14;
}

.bg-orange {
  background-color: #fd7e14;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.text-yellow {
  color: #EBBB54;
}

.bg-yellow {
  background-color: #EBBB54;
}

.border-yellow {
  border-color: #EBBB54 !important;
}

.text-green {
  color: #94BA65;
}

.bg-green {
  background-color: #94BA65;
}

.border-green {
  border-color: #94BA65 !important;
}

.text-teal {
  color: #20c997;
}

.bg-teal {
  background-color: #20c997;
}

.border-teal {
  border-color: #20c997 !important;
}

.text-cyan {
  color: #0981AC;
}

.bg-cyan {
  background-color: #0981AC;
}

.border-cyan {
  border-color: #0981AC !important;
}

.text-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.border-white {
  border-color: #FFFFFF !important;
}

.text-morado {
  color: #6662AC;
}

.bg-morado {
  background-color: #6662AC;
}

.border-morado {
  border-color: #6662AC !important;
}

.text-gray {
  color: #e9ecef;
}

.bg-gray {
  background-color: #e9ecef;
}

.border-gray {
  border-color: #e9ecef !important;
}

.text-darkgray {
  color: #4D4D4D;
}

.bg-darkgray {
  background-color: #4D4D4D;
}

.border-darkgray {
  border-color: #4D4D4D !important;
}

.text-bluebf {
  color: #009fdf;
}

.bg-bluebf {
  background-color: #009fdf;
}

.border-bluebf {
  border-color: #009fdf !important;
}

.text-bluebf-light {
  color: #dbf1f9;
}

.bg-bluebf-light {
  background-color: #dbf1f9;
}

.border-bluebf-light {
  border-color: #dbf1f9 !important;
}

.text-bluebf-mid {
  color: #39b2dd;
}

.bg-bluebf-mid {
  background-color: #39b2dd;
}

.border-bluebf-mid {
  border-color: #39b2dd !important;
}

.cookies-alert {
  position: fixed;
  bottom: 1rem;
  left: 0rem;
  width: 100%;
  padding: 30px 4rem;
  background: rgba(0, 0, 0, 0.8);
  text-align: left;
  z-index: 100000;
  font-weight: 100;
  color: #e6e6e6;
  font-size: 12px;
}

.cookies-alert a {
  color: #e6e6e6;
  text-decoration: underline;
}

.cookies-alert-close {
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background: none;
  opacity: 0.40;
  color: #ffffff;
}

@media (min-width: 576px) {
  .cookies-alert {
    right: 0px;
    width: 100%;
  }
}

.anchor-heading {
  padding-top: 140px;
  margin-top: -140px;
}

.back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  width: 50px;
  opacity: 0;
  transition: opacity 300ms linear, bottom 150ms linear;
}

.back-to-top.active {
  opacity: 1;
}

.summernote-font-size-fix span[style] {
  font-size: inherit !important;
}

.filter-white {
  filter: brightness(0) invert(1);
}

.filter-black {
  filter: brightness(0);
}

.img-circle {
  display: inline-flex;
  width: 8rem;
  height: 8rem;
  border-radius: 2.5rem;
  justify-content: center;
  align-items: center;
}

.img-circle img {
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.img-circle.img-circle-sm {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}

.img-circle.img-circle-sm img {
  max-width: 1rem;
  max-height: 1rem;
}

footer .footer-brand {
  filter: brightness(0) invert(1);
}

footer .footer-nav {
  margin: 0;
}

footer .footer-nav a {
  color: white;
  text-transform: uppercase;
  font-size: .9rem;
}

footer .footer-nav .list-inline-separator {
  opacity: .4;
}

@media (max-width: 991px) {
  footer .footer-brand img {
    margin-bottom: 2rem;
    max-width: 200px;
  }
  footer .footer-mapama {
    margin-top: 2rem;
    max-width: 200px;
  }
  footer .footer-nav a {
    font-size: .8rem;
  }
}

.organization-logos img {
  max-height: 80px;
}

.organization-logos-sm img {
  max-height: 50px;
}

.promoter-logos img {
  max-height: 60px;
}

.projects-carousel {
  padding: 0 4rem;
}

.projects-carousel .carousel-control-prev-icon {
  fill: #343a40;
}

.projects-carousel .carousel-control-prev, .projects-carousel .carousel-control-next {
  width: 4rem;
}

.projects-carousel > .carousel-inner {
  border-radius: .3rem;
  overflow: hidden;
  background-color: #dee2e6;
}

.projects-carousel .projects-carousel-item {
  background-size: cover;
  height: 200px;
  padding: 1rem;
}

.projects-carousel .projects-carousel-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: background-color 300ms linear;
}

.projects-carousel .projects-carousel-item:hover {
  text-decoration: none;
}

.projects-carousel .projects-carousel-item:hover:after {
  background-color: rgba(0, 0, 0, 0);
}

.projects-carousel .projects-carousel-item h3 {
  z-index: 2;
}

.indicator {
  width: 270px;
  display: inline-block;
  vertical-align: top;
}

.posts .bricklayer-column-sizer {
  width: 100% !important;
}

@media (min-width: 992px) {
  .posts .bricklayer-column-sizer {
    width: 50% !important;
  }
}

.posts .bricklayer-column {
  padding-left: .5rem;
  padding-right: .5rem;
}

.posts .bricklayer .bricklayer-column:nth-child(2) {
  padding-left: 0;
}

.posts .bricklayer .bricklayer-column:last-child {
  padding-right: 0;
}

.post-thumb {
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  background-position: center center;
  background-size: cover;
  margin-right: 1rem;
}

.post-thumb-sm {
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
}

.album img,
.album video {
  height: 200px;
  border: 1px solid #dee2e6;
}

.album.album-sm {
  overflow: hidden;
  max-height: 216px;
}

.album.album-sm img,
.album.album-sm video {
  height: 68px;
}

.link-working-area {
  text-decoration: none;
}

.link-working-area:hover {
  text-decoration: none;
  opacity: 0.5;
}

.note-float-left {
  margin-right: 2rem;
}

.note-float-right {
  margin-left: 2rem;
}

.bg-blueabf {
  background-color: #009fdf;
}

hr {
  border-top: 1px solid #009fdf;
}

h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
  color: #009fdf;
  font-weight: bold;
}

.fa {
  color: #009fdf;
}

.btn .fa {
  color: white;
}

.obxectivo h3 {
  color: white;
}

.background-cover-image h2, .background-cover-image h3, .background-cover-image h4, .background-cover-image h5, .background-cover-image h6 {
  color: white;
}

.btn-blueabf {
  background-color: #009fdf;
  border-radius: 2rem;
  border: 0px;
}

.bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark .h3, .bg-dark .h4, .bg-dark .h5, .bg-dark .h6 {
  color: white;
}

.bg-dark label {
  color: white;
}

.banner {
  background-image: url("/images/public/home/PORTADA2.jpg");
  text-align: center;
  color: #fff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* .banner::after {
  content:"";
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0, 49, 99, 0.5);
  z-index: 1;
} */
@media (min-width: 768px) {
  .banner-text {
    padding: 20% 0 20% 0;
  }
}

@media (max-width: 991px) {
  .banner-text {
    padding: 15% 0 10% 0;
  }
}

.banner-heading {
  text-align: center;
  /* max-width: 35em; */
  color: #fff;
  filter: none;
  z-index: 200;
}

.uppercase {
  text-transform: uppercase;
}

.banner-sub-heading {
  color: #fff;
}

@media (min-width: 768px) {
  .banner-heading img {
    max-height: 9em;
    margin-bottom: 1rem;
  }
}

@media (max-width: 991px) {
  .banner-heading img {
    max-height: 4em;
    margin-bottom: 2rem;
  }
}

/* mobile view */
@media (max-width: 500px) {
  .banner-text {
    padding: 150px 0 150px 0;
  }
  .banner-heading {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .banner-sub-heading {
    font-size: 10px;
    font-weight: 200;
    line-height: 10px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .banner-text {
    padding: 150px 0 150px 0;
  }
  .banner-sub-heading {
    font-size: 23px;
    font-weight: 200;
    line-height: 23px;
    margin-bottom: 40px;
  }
}

.form-group input {
  background-color: #E9E9E9;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.25;
  color: #495057;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
