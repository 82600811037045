@import '~bootstrap/scss/mixins/breakpoints';
@import "common";
@import 'navbar';
@import 'cookies_alert';



.anchor-heading {
  padding-top: 140px;
  margin-top: -140px;
}

.back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  width: 50px;
  opacity: 0;

  transition: opacity 300ms linear, bottom 150ms linear;

  &.active {
    opacity: 1;
  }
}

.summernote-font-size-fix span[style] {
  font-size: inherit !important;
}

.filter-white {
  filter: brightness(0) invert(1);
}

.filter-black {
  filter: brightness(0);
}

.img-circle {
  display: inline-flex;
  width: 8rem;
  height: 8rem;
  border-radius: 2.5rem;
  justify-content: center;
  align-items: center;
  img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
  &.img-circle-sm {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    img {
      max-width: 1rem;
      max-height: 1rem;
    }
  }
}

footer {
  .footer-brand {
    filter: brightness(0) invert(1);
  }

  .footer-nav {
    margin: 0;
    a {
      color: white;
      text-transform: uppercase;
      font-size: .9rem;
    }
    .list-inline-separator {
      opacity: .4;
    }
  }

  @include media-breakpoint-down(md) {
    .footer-brand img {
      margin-bottom: 2rem;
      max-width: 200px;
    }
    .footer-mapama {
      margin-top: 2rem;
      max-width: 200px;
    }
    .footer-nav {
      a {
        font-size: .8rem;
      }
    }
  }
}

.organization-logos {
  img {
    max-height: 80px;
  }
}

.organization-logos-sm {
  img {
    max-height: 50px;
  }
}

.promoter-logos {
  img {
    max-height: 60px;
  }
}

.projects-carousel {
  .carousel-control-prev-icon {
    fill: theme-color("dark");
  }

  padding: 0 4rem;
  .carousel-control-prev, .carousel-control-next {
    width: 4rem;
  }

  > .carousel-inner {
    border-radius: .3rem;
    overflow: hidden;
    background-color: $gray-300;
  }

  .projects-carousel-item {
    background-size: cover;
    height: 200px;
    padding: 1rem;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparentize(black, 0.5);
      z-index: 1;
      transition: background-color 300ms linear;
    }

    &:hover {
      text-decoration: none;

      &:after {
        background-color: transparentize(black, 1);
      }
    }

    h3 {
      z-index: 2;
    }
  }
}

.indicator {
  width: 270px;
  display: inline-block;
  vertical-align: top;
}

.posts {
  @include media-breakpoint-up(xs) {
    .bricklayer-column-sizer {
      width: 100% !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .bricklayer-column-sizer {
      width: 50% !important;
    }
  }
  .bricklayer-column {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .bricklayer {
    .bricklayer-column:nth-child(2) {
      padding-left: 0;
    }
    .bricklayer-column:last-child {
      padding-right: 0;
    }
  }
}


.post-thumb {
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  background-position: center center;
  background-size: cover;
  margin-right: 1rem;
}

.post-thumb-sm {
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
}

.album {
  img,
  video {
    height: 200px;
    border: 1px solid $gray-300;
  }
  &.album-sm {
    img,
    video {
      height: 68px;
    }
    overflow: hidden;
    max-height: 216px;
  }
}

.link-working-area {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    opacity: 0.5;
  }
}

.note-float-left {
  margin-right: 2rem;
}

.note-float-right {
  margin-left: 2rem;
}


.bg-blueabf{
  background-color: #009fdf;
}

hr {

  border-top: 1px solid rgba(0, 159, 223, 1);
}
 h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {

  color:  rgba(0, 159, 223, 1);
  font-weight:bold;
}

.fa {
  color:  rgba(0, 159, 223, 1);
}

.btn .fa {
  color:  white;
}

.obxectivo h3{

  color:  white;
}

.background-cover-image h2, .background-cover-image h3, .background-cover-image h4, .background-cover-image h5, .background-cover-image h6 {

  color:  white;
}

.btn-blueabf{
  background-color: #009fdf;
  border-radius: 2rem;
  border: 0px;
}

.bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark .h3, .bg-dark .h4, .bg-dark .h5, .bg-dark .h6 {
color:  white;
}

.bg-dark label {
  color:  white;
  }




.banner{
  background-image:url("/images/public/home/PORTADA2.jpg");
	text-align: center;
    color: #fff;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


/* .banner::after {
  content:"";
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0, 49, 99, 0.5);
  z-index: 1;
} */



@include media-breakpoint-up(md) {
  .banner-text{
    padding:20% 0 20% 0;
  }
}
@include media-breakpoint-down(md) {
  .banner-text{
    padding:15% 0 10% 0;
  }
}



.banner-heading{
  text-align: center;
	/* max-width: 35em; */
  color:#fff;
  filter:none;
  z-index: 200;

}

.uppercase{
  text-transform: uppercase;
}


.banner-sub-heading{

	color:#fff;
}


.banner-heading {
  @include media-breakpoint-up(md) {
  img {
    max-height: 9em;
    margin-bottom: 1rem;
  }
}
  @include media-breakpoint-down(md) {
    img {
      max-height: 4em;
      margin-bottom: 2rem;
    }
  }
}



/* mobile view */
@media (max-width:500px)
{

	.banner-text{
	padding:150px 0 150px 0;
}
.banner-heading{
	font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
}
.banner-sub-heading{
	font-size: 10px;
    font-weight: 200;
    line-height: 10px;
    margin-bottom: 40px;
}

}

@media (max-width:768px){
	.banner-text{
	padding:150px 0 150px 0;
}
	.banner-sub-heading{
	font-size: 23px;
    font-weight: 200;
    line-height: 23px;
    margin-bottom: 40px;
}
}

.form-group input{
  background-color: #E9E9E9;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.25;
  color: #495057;
 
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}


.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}